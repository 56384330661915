<script setup>

    const props = defineProps({
        "text": { type: String },
        "icon": { type: String, default: "far fa-plus" },
    });
</script>

<template>
    <div class="d-flex align-items-center gap-2 border justify-content-center" style="height: 3em; border-bottom-left-radius: 8px; border-bottom-right-radius: 8px;">
        <template v-if="icon">
            <div class="text-primary">
                <i :class="icon" v-if="icon"></i>
            </div>
        </template>
        <template v-if="text">
            <span class="text-primary fw-medium">{{ text }}</span>
        </template>
        <slot />
    </div>
</template>

<style scoped>
</style>